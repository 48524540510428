import type { ComponentType } from "react"
import { useState, useEffect, useRef } from "react"

// hoc for component with absolute link to django app pages
// change origin from initial to correct for env
// if origin includes divorce.com it is production and we use initial
// if origin does not include divorce.com it is staging and we change origin
export function withLinkCorrectionToDjangoApp(Component): ComponentType {
    return (props) => {
        const [newHref, setNewHref] = useState(props.href)

        useEffect(() => {
            if (!window.location.origin.includes("divorce.com")) {
                const oldUrl = new URL(props.href)
                const newUrl = new URL(
                    oldUrl.pathname,
                    "https://staging.completecase.com:8190"
                )
                setNewHref(newUrl.href)
            }
        }, [])

        return <Component {...props} href={newHref} />
    }
}
